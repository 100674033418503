<template lang='pug'>
  v-app
    v-snackbar( v-model="Ayudas.mostrar_snackbar" :timeout='5000') 
      span {{ Ayudas.mensaje_de_snackbar }}
      v-btn( color='red' text @click='Ayudas.mostrar_snackbar=false' ) Cerrar
    Cargando_overlay(absolute)
    router-view
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  created(){
    this.obtener_datos()
    this.obtener_entradas()
    this.obtener_etiquetas()
  },
  computed:{
    ...mapState({
      Ayudas : ({Ayudas}) => Ayudas,
    }),
  },
  components: {
    Cargando_overlay: ()=>import('./views/vistas/componentes/cargando_overlay'),
  },
  methods: {
    ...mapActions({
      obtener_datos: 'Landing_set/obtener',
      obtener_entradas: 'Blog/obtener_todos',
      obtener_etiquetas: 'Etiquetas/obtener_todos',
    }),
  },
}
</script>
<style lang="sass">
  a
    cursor: pointer
  v-img
    cursor: pointer
  html, body 
    overflow-x: hidden
  body 
    position: relative
</style>