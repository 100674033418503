<template lang="pug">
div
    Cargando_overlay(v-if='Cargando')
    div(v-else)
        Navegador
        router-view
        Novedades_container(v-if='ruta_actual!="blog"&&ruta_actual!="contacto"&&ruta_actual!="entrada"&&ruta_actual!="nosotros"&&ruta_actual!="construcción"&&ruta_actual!="saneamiento"&&ruta_actual!="arquitectura"')
        Contacto_container(v-if='ruta_actual=="inicio"||ruta_actual=="contacto"||ruta_actual=="servicios"||ruta_actual=="saneamiento"||ruta_actual=="construcción"||ruta_actual=="arquitectura"' )
        Pie_de_pagina
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ruta_actual(){
            return this.$route.name
        },
        ...mapState({
            Cargando: ({Ayudas}) => Ayudas.cargando,
        }),
    },
    components: {
        Cargando_overlay: ()=>import('./componentes/cargando_overlay'),
        Navegador: ()=>import('./componentes/navegador'),
        Pie_de_pagina: ()=>import('./componentes/pie_de_página/pie_de_página'),
        Novedades_container: ()=>import('./componentes/novedades_container'),
        Contacto_container: ()=>import('./componentes/contacto_container'),
    },
}
</script>