import Vue from 'vue'
import Vuex from 'vuex'
import {toSlug} from '@/Helper'
Vue.use(Vuex)
export default {
  namespaced:true,
  state: {
    mensaje_de_snackbar: '',
    cargando: false,
    mostrar_snackbar: false,
    reglas: {
      requerido: value => !!value || '¡Necesitamos este dato!',
      mínimo_ocho: value => value && value.length >= 8 || '¡Debe haber mínimo 8 letras!',
      mínimo_once: value => value && value.length>=11 || '¡Debe haber mínimo 11 letras!',
      email_valido: v => /.+@.+\..+/.test(v) || 'El correo debe ser válido.',
      solo_letras: value =>  /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g.test(value) || '¡Solo debe tener letras!',
      solo_números: value => /[0-9]+/.test(value) || '¡Solo debe tener números!'
    },
  },
  mutations: {
    _activar_snackbar: (state, mensaje)=>{
      state.mensaje_de_snackbar = mensaje 
      state.mostrar_snackbar = true
    },
    alterar_cargando: (state, estado)=>{
      state.cargando = estado
    },
  },
  actions: {
    activar_snackbar: ({commit, state}, mensaje)=>{
      state.mensaje_de_snackbar = mensaje
      commit('_activar_snackbar', state.mensaje_de_snackbar)
    },
  },
}