import moment from 'moment'
moment.locale("es-ES")

const secondsToFullTimestamp = second => moment(second,'X').format('DD/MM/YYYY hh:mm')
const obtener_periodo = () => moment(new Date()).format('YYYYMM').toUpperCase()
const getTimeStamp = () => moment(new Date()).format('DD/MM/YYYY hh:mm').toUpperCase()

const periodo_de_fecha = second=> moment(second, 'X').format('YYYYMM').toUpperCase()


const toNumber = number => Number(Number(number).toFixed(2)) 
const secondsToTimestamp = second => moment(second,'X').format('DD/MM/YYYY')
const secondsToFormatDateInput = second => moment(second,'X').format('YYYY-MM-DD')
const cleanObject = object=> Object.keys(object).map(item=>object[item]='')
const getStringToday = () => moment().format('YYYY-MM-DD')
const toSlug = str=> {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str
  }


const receiptNumberFormat = receiptNumber => (receiptNumber)? (receiptNumber).slice(0, 4) + '-' + (receiptNumber).slice(4) : null

const readCookie = name => {

    let nameEQ = name + "="
    let ca = document.cookie.split(';')

    for(let  i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length)
        if (c.indexOf(nameEQ) == 0) {
        return decodeURIComponent( c.substring(nameEQ.length,c.length) )
        }
    }
    return null
}

const obtener_llave = (arreglo, llave_de_comparacion, comparacion, llave_de_respuesta ) =>{
    let data = arreglo.filter(item => item[llave_de_comparacion] == comparacion)
    if(data.length>0) return data[0][llave_de_respuesta]
}


export {
    secondsToTimestamp,
    cleanObject,
    secondsToFullTimestamp,
    secondsToFormatDateInput,
    toSlug,
    receiptNumberFormat,
    getStringToday,
    readCookie,
    obtener_periodo,
    toNumber,
    getTimeStamp,
    obtener_llave,
    periodo_de_fecha
}