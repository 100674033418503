import Vue from 'vue'
import Vuex from 'vuex'
import Landing_set from './componentes/Landing_set'
import Ayudas from './componentes/Ayudas'
import Blog from './componentes/Blog'
import Etiquetas from './componentes/Etiquetas'
import Contacto from './componentes/Contacto'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    Ayudas,
    Landing_set,
    Blog,
    Etiquetas,
    Contacto,
  }
})