import Vue from 'vue'
import Vuex from 'vuex'
import {functions} from '@/FirebaseConfig'
Vue.use(Vuex)
export default {
    namespaced:true,
    actions: {
        enviar_correo_electronico:  (context, {titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})=>{
            return new Promise(async(resolve, rejected)=>{
                try {
                    const _enviar_correo = functions.httpsCallable('correo_electronico-enviar') 
                    await _enviar_correo({
                        titulo,
                        correo_electronico,
                        cuerpo,
                        asunto,
                        configuracion_de_correo
                    })
                    resolve()
                } catch (error) {
                    rejected(error)
                }
            })
        },
    },
}
