import Vue from 'vue'
import Router from 'vue-router'
import {auth} from '@/FirebaseConfig'
import Raíz_principal from '../views/vistas/raíz_principal'

Vue.use( Router )
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Raíz_principal,
      children: [
        {
          path: '',
          name: 'inicio',
          component: ()=>import('../views/vistas/inicio/inicio'),
        },
        {
          path: 'servicios',
          name: 'servicios',
          component: ()=>import('../views/vistas/servicios/servicios'),
          children: [
            {
              path: 'construccion',
              name: 'construcción',
              component: ()=>import('../views/vistas/servicios/construcción/servicios'),
            },
            {
              path: 'saneamiento_fisico_legal',
              name: 'saneamiento',
              component: ()=>import('../views/vistas/servicios/saneamiento/saneamiento'),
            },
            {
              path: 'arquitectura_e_ingenieria',
              name: 'arquitectura',
              component: ()=>import('../views/vistas/servicios/arquitectura/arquitectura'),
            },
          ],
        },
        {
          path: 'nosotros',
          name: 'nosotros',
          component: ()=>import('../views/vistas/nosotros/nosotros'),
        },
        {
          path: 'blog',
          name: 'blog',
          component: ()=>import('../views/vistas/blog/blog'),
          children: [
            {
              path: 'entrada/:slug?',
              name: 'entrada',
              component: ()=>import('../views/vistas/blog/componentes/entrada/entrada'),
            }
          ],
        },
        {
          path: 'contacto',
          name: 'contacto',
          component: ()=>import('../views/vistas/contacto/contacto'),
        },
      ],
    },
  ]
})
router.beforeEach(async (to,from,next)=> {
  const currentUser = auth.currentUser
  const requiresAuth = to.matched.some(record=> record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    return next ({ name:'sesion' } )
  }
  else next()
})
export default router