import firebase from "@firebase/app"
require("firebase/firestore")
require('firebase/auth')
require('firebase/storage')
require("firebase/functions");
require("firebase/analytics")

let config = {
  apiKey: "AIzaSyBG5CxVvrOmEt0HEDmpegYvO0SUzpGpa6E",
  authDomain: "web-siarcos.firebaseapp.com",
  projectId: "web-siarcos",
  storageBucket: "web-siarcos.appspot.com",
  messagingSenderId: "614690636017",
  appId: "1:614690636017:web:887d71d2ea9b9ba25ee950",
  measurementId: "G-3LLRMVGBZ7"
}
 
firebase.initializeApp(config)
firebase.analytics()

const db = firebase.firestore()
const _firestore = firebase.firestore
const auth = firebase.auth()
const functions = firebase.functions()
const _auth = firebase.auth
const storage = firebase.storage()
const ColeccionLanding = db.collection('Landing').doc('Contenido')
const ColeccionLandingImagenes = db.collection('Landing').doc('Imágenes')
const Colección_blog = db.collection('Landing').doc('Contenido').collection('Blog')
const Colección_etiquetas = db.collection('Landing').doc('Contenido').collection('Etiquetas')


const enviar_correo_electronico = functions.httpsCallable('correo_electronico-enviar')

export {
  db,
  functions,
  auth,
  _auth,
  storage,
  firebase,
  ColeccionLanding,
  _firestore,
  ColeccionLandingImagenes,
  enviar_correo_electronico,
  Colección_blog,
  Colección_etiquetas,
}